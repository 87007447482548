<template>
  <div
    class="app-content content"
    :class="[{'show-overlay': $store.state.app.shallShowOverlay}, $route.meta.contentClass]"
    style="padding: 0px !important"
    :style="{ height: props.height, width: props.width, 'max-height': props.height, 'max-width': props.width }"
  >
    <div
      v-if="false"
      class="content-overlay"
    />
    <div class="header-navbar-shadow" />
    <transition
      :name="routerTransition"
      mode="out-in"
    >
      <div
        class="content-area-wrapper"
        :class="contentWidth === 'boxed' ? 'container p-0' : null"
        :style="{ height: props.height, width: props.width, 'max-height': props.height, 'max-width': props.width }"
      >
        <slot name="breadcrumb">
          <app-breadcrumb />
        </slot>
        <portal-target
          name="content-renderer-sidebar-left"
          slim
        />
        <div
          class="content-right"
          :class="{ 'w-100': props.fullscreen }"
        >
          <div class="content-wrapper">
            <div class="content-body">
              <slot />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import useAppConfig from '@core/app-config/useAppConfig'

import { mapGetters } from 'vuex'

export default {
  components: {
    AppBreadcrumb,
  },
  computed: {
    ...mapGetters({
      props: 'getProps',
    }),
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig()

    return {
      routerTransition, contentWidth,
    }
  },
}
</script>

<style>

</style>
